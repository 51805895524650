import { Splide } from "@splidejs/splide"


document.addEventListener( 'DOMContentLoaded', function() {

    new Splide('#image-slider', {
        loop: true,
        speed: 1200,
        autoplay: true,
        interval: 5000,
        lazyLoad: 'nearby'
    }).mount()

    // new Splide('#text-slider', {
    //     loop: true,
    //     speed: 1200
    // }).mount()



})